<template>
  <div class="auth-book-outer">
    <div class="auth-book-box m-spill">
      <h1 class="font-bold title">{{$t('authBook.authBookTitle')}}</h1>

      <p class="detail">
        <span>{{$t('authBook.authBookText1')}}</span>
        <b>&nbsp;{{dataInfo.main || ''}}&nbsp;</b>
        <span>{{$t('authBook.authBookText2')}}</span>
        <b>&nbsp;{{dataInfo.project}}&nbsp;</b>
        <span>{{$t('authBook.authBookText3')}}</span>
        <b>&nbsp;{{dataInfo.auth_scene | toAuthScene}} - {{dataInfo.use_mode}}&nbsp;</b>
        <span>{{$t('authBook.authBookText4')}}</span>
        <b>&nbsp;{{dataInfo.music_name}} - {{dataInfo.suplr_name}}，</b>
        <span>{{$t('authBook.authBookText5')}}</span>
        <!-- 项目期限 start -->
        <b
          v-if="dataInfo.auth_time === this.$val.AuthPeriod.forever"
        >{{$t('authBook.authBookText6')}}</b>
        <b v-else>{{dataInfo.start_at}} - {{dataInfo.end_at}}，</b>
        <!-- 项目期限 end -->
        <span>{{$t('authBook.authBookText7')}}</span>
        <b>{{dataInfo.auth_area | toArea}}</b>
        <span>。</span>
      </p>

      <h2 class="font-bold sub-title">{{$t('authBook.authBookSubTitle')}}</h2>

      <ul class="list">
        <li>
          <span>{{$t('authBook.authBookListGoodName')}}</span>
          <b>{{dataInfo.music_name}} - {{dataInfo.suplr_name}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuther')}}</span>
          <b>{{dataInfo.suplr_name}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuthCode')}}</span>
          <b>{{dataInfo.code}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuthMain')}}</span>
          <b>{{dataInfo.main}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuthProject')}}</span>
          <b>{{dataInfo.project}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuthScene')}}</span>
          <b>{{dataInfo.auth_scene | toAuthScene}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListChannel')}}</span>
          <b>{{dataInfo.use_mode}}</b>
        </li>
        <li>
          <span>{{$t('authBook.authBookListAuthTime')}}</span>
          <b>{{dataInfo.create_at}}</b>
        </li>

        <li class="m-top-base">{{$t('authBook.authBookListConnectTip')}}{{dataInfo.contact}}</li>
      </ul>

      <div class="footer">
        <div class="com-name">{{$t('authBook.authBookCompanyName')}}</div>
        <div class="time">{{curDate}}</div>
        <!-- <img class="seal" :src="require('@/assets/images/seal.png')" alt /> -->
      </div>
    </div>
  </div>
</template>

<script>
  import {AuthAreaOptions, AuthTypeOptions, BusinessSceneOptions} from '@/config/options';
  import {toOptionName} from '@/utils/function';

  export default {
    data() {
      return {
        dataInfo: {},
        curDate: ''
      }
    },

    created() {
      let license_id = this.$route.params.auth_id || '';

      if (license_id && ~license_id) {
        this.getAuthInfo(license_id);
      } else {
        let query = this.$route.query;

        query = this.calcTime(query);

        this.dataInfo = query;
      }

      this.curDate = this.$moment().format('ll');
    },

    filters: {
      toArea(value) {
        return toOptionName(AuthAreaOptions, value);
      },

      toAuthType(value) {
        return toOptionName(AuthTypeOptions, value);
      },

      toAuthScene(value) {
        return toOptionName(BusinessSceneOptions, value);
      },
    },


    methods: {
      calcTime(params = {}) {
        let value = {...params};
        let {create_at, auth_time} = value;
        let moment = this.$moment;

        if (!create_at) {
          value.start_at = moment().format('YYYY-MM-DD');
        } else {
          value.start_at = create_at;
        }

        if (auth_time === this.$val.AuthPeriod.year) {
          value.end_at = moment().set('year', moment().get('year') + 1).format('YYYY-MM-DD');
        }

        return value;
      },

      getAuthInfo(license_id) {
        let params = {license_id};

        this.$axios.AuthInfo(params).then(res => {
          const data = res.data;

          if (data && data.code == 0) {
            let newData = data.data;

            this.dataInfo = this.calcTime(newData);
          }
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  .auth-book-outer {
    width: 100%;
    min-height: 100%;
    background-color: #ddd;
  }

  .auth-book-box {
    position: relative;
    width: 842px;
    height: 1191px;
    margin: 0 auto;
    text-align: center;
    background-color: #fff;
    font-family: Alibaba-PuHuiTi-R, Alibaba-PuHuiTi;

    .title {
      font-size: 36px;
      margin-top: 90px;
      margin-bottom: 50px;
    }

    .sub-title {
      font-size: 30px;
      font-weight: 500;
      margin-top: 80px;
    }

    .detail {
      width: 700px;
      font-size: 18px;
      font-weight: normal;
      color: #000000;
      line-height: 2;
      text-align: justify;
      text-indent: 2em;
      margin: 0 auto;
    }

    .list {
      width: 452px;
      margin: 0 auto;
      text-align: left;
      font-size: 18px;
      font-weight: normal;
      color: #000000;
      line-height: 40px;
      margin-top: 33px;

      > li {
        white-space: nowrap;
      }
    }

    .footer {
      position: absolute;
      bottom: 40px;
      right: 30px;
      width: 320px;
      height: 220px;
      font-size: 20px;

      .com-name {
        margin-top: 55px;
      }

      .seal {
        width: 190px;
        height: 190px;
        position: absolute;
        top: 10px;
        left: 43px;
        z-index: 2;
      }

      .time {
        margin-top: 39px;
        display: inline-block;
        margin-left: 100px;
      }
    }
  }
</style>
